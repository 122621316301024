import * as React from "react"
import {Helmet} from "react-helmet";
import SupportForm from "../components/SupportForm";
import Layout from "../components/Layout";
import Section from "../components/Section";
import LogoLight from "images/logo-light.svg";
import Phone1 from "images/en-section-1@2x.png";
import Phone2 from "images/en-section-2@2x.png";
import Phone3 from "images/en-section-3@2x.png";
import Phone4 from "../images/en-section-4@2x.png";
import Phone5 from "../images/en-section-5@2x.png";
import Phone6 from "images/en-section-6@2x.png";
import IconArrowed from "images/p-icon-arrowed.svg";
import IconCurrencies from "images/icon-currencies.svg";
import IconCurrencies2 from "../images/icon-currencies-2.svg";
import IconPrivacy from "images/icon-privacy.svg";
import './index.scss';
import Badges from "../components/Badges";
import BgCircle from "../components/BgCircle";
import LangBar from "components/LangBar";
import StickyBar from "components/StickyBar";


import IconPhones from "../images/icon-phones.svg";

const Homepage = () => {

  return <Layout lang={"en"}>
    <Helmet>
      <meta charSet="utf-8"/>
      <title>Paramla</title>
    </Helmet>

    <LangBar/>
    <StickyBar/>
    <BgCircle/>

    <Section id="1" bg={"dark-gradient"} image={Phone1}>
      <img className="section-text-image" alt="Paramla logo" src={LogoLight}/>
      <div className="section-text section-text--light">
        Value and percentage break-downs<br />
        of your entire assets,<br />
        on a single screen, at your fingertips!
      </div>
      <div className="hide-on-mobile" style={{marginTop: 40}}><Badges/></div>
    </Section>

    <Section id="2" bg={"white"} imgLeft={true} image={Phone2}>
      <img className="section-text-image" alt="Paramla logo" src={IconArrowed}/>
      <div className="section-text section-text--dark">
        <div>
          Picking from<br />
          <span className={"text--ul"}>25 thousand assets</span> under 8 categories<br/>
          with prices and '<span className={"text--ul"}>Top Performers</span>'!<br/>
        </div>
        <div className="section-subtext">
          Turkish Lira | Stock (BIST) | Commodity | Foreign Currency<br/>
          Fund | Eurobond | Crypto Currency | US Exchanges
        </div>
      </div>
    </Section>

    <Section id="3" bg={"light"} image={Phone3}>
      <img className="section-text-image" alt="Paramla logo" src={IconCurrencies}/>
      <div className="section-text section-text--dark">
        With trends <br/>
        in any period you pick!
      </div>
    </Section>

    <Section id="4" bg={"white"} imgLeft={true} image={Phone4}>
      <img className="section-text-image" alt="Paramla logo" src={IconCurrencies2}/>
      <div className="section-text section-text--dark">
        <div>
          With profit/loss amounts & margins,<br/>
          in 80 currencies!
        </div>
      </div>
    </Section>

    <Section id="5" bg={"light"} image={Phone5}>
      <img className="section-text-image" alt="Paramla logo" src={IconPhones}/>
      <div className="section-text section-text--dark">
        <div>
          Creating and <span className={"text--ul"}>sharing</span> / <span className={"text--ul"}>co-managing</span> <br/>
          up to 20 portfolios,<br/>
          syncing up to 10 devices!
        </div>
        <div className="section-subtext">
          In English or Turkish with a single tap!
        </div>
      </div>
    </Section>

    <Section id="6_yeni" bg={"dark"}>
      <img className="section-text-image" alt="Paramla logo" src={IconPrivacy}/>
      <div className="section-text section-text--light">
        <div>
          Without sharing<br />
          personal data!
        </div>
        <div className="section-subtext">
          no sign-up | sign-in;<br/>
          <span className={"text--ul"}>always remaining anonymous</span>
        </div>
      </div>
    </Section>

    <Section id="6" bg={"white"} image={Phone6}>
      <Badges/>
    </Section>
    <Section id="7" bg={"light"}>
      <SupportForm
        title1={{label: "Anything", isBold: false}}
        title2={{label: "you would like to ask", isBold: false}}
        title3={{label: "or say:", isBold: false}}
        placeholderInput={"E-mail address"}
        placeholderText={"Your message"}
        successMessage1={"Thanks for reaching out"}
        successMessage2={"We shall respond soon."}
      />
    </Section>
  </Layout>
}

export default Homepage;
